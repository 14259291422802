import React, { useEffect } from "react";
import i18n from "./locales/i18n";
import Main from "./pages/Main";
import { useSelector } from 'react-redux';
import {
  selectedLang
} from './redux/languageReducer';
const App = () => {
  const language = useSelector(selectedLang);
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  return (
    <Main />
  )
}
export default App;
