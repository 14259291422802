import Mexc from '../../assets/images/mexc-logo.svg'
import oneinch from '../../assets/images/1inch.svg'
import Matcha from '../../assets/images/Matcha.svg'
import Bitrue from '../../assets/images/Biture.svg'
import Bitforex from '../../assets/images/Bitforex.png'
import Digifinex from '../../assets/images/Digifinex.svg'
import PancakeSwap from '../../assets/images/PancakeSwap.svg'
export const TRANSLATIONS_EN = {
   /// unidefcoin Page Content
   top_btn_txtL: "Go to Unidef.Org",
   title1: "U is for Unidef ",
   title2: "— and ",
   title3: "Universal Adoption.",
   subtitle: "Utility. Community. Exposure.",
   sticky_button_text: "BUY/SELL",
   sticky_button_token_name1: "Unidef Coin",
   sticky_button_token_name2: "(Ticker: U)",
   sticky_button_text1: "READ DISCLAIMER",
   stick_highlight:"unidefcoin.com ",
   sticky_read:"This information is drawn from other sources on the internet. ",
   sticky_read1:"does not endorse or recommend any exchanges or make any representations with respect to exchanges or the purchase or sale of digital assets more generally. It’s advisable to conduct your own due diligence before relying on any third party or third-party technology, and providers may vary significantly in their compliance, data security, and privacy practices.",
   sticky_read2: "This website does not constitute an offer to purchase or solicitation to sell, nor is it a recommendation to buy or sell, any token or other product. Purchasing and selling tokens is inherently risky and holders must bear their risk of loss. Neither the authors of this website nor any participants in the Unidef project accept any liability for losses or taxes that holders, purchasers or sellers of Unidef (Ticker: U) may incur. The value of U may decrease and may be highly volatile.",
   sticky_read3: "This website is not directed towards any person located in a jurisdiction where purchasing, selling or holding U is prohibited or regulated. Consult your local laws before transacting in any cryptocurrency.",
   follow_text: "Follow Unidef on",
   footer_contact_address_text:"Unidef Coin Contract Address (BEP20): ", 
   footer_contact_address_copy:"0x89dB9B433FeC1307d3dc8908f2813e9f7a1d38F0",
   found_txt:"© 2022-2023, U FOUNDATION",
   go_to_unidef: "Go to Unidef.Org",
   find_unidef_coin:"FIND ABOUT UNIDEF COIN",
   sticky_customTile:
   [
     {
       id: 1,
       url: Mexc,
       title: 'Mexc',
       link: "https://www.mexc.com/exchange/U_USDT",
     },
     {
       id: 2,
       url: oneinch,
       title: '1inch',
       link: "https://app.1inch.io/#/56/simple/swap/BNB/0x89db9b433fec1307d3dc8908f2813e9f7a1d38f0/import-token",
     },
     {
       id: 3,
       url: Matcha,
       title: 'Matcha',
       link: "https://www.matcha.xyz/markets/56/0x89db9b433fec1307d3dc8908f2813e9f7a1d38f0/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
     },
     {
       id: 4,
       url: Bitrue,
       title: 'Bitrue',
       link: "https://www.bitrue.com/trade/u_usdt",
     },
     {
       id: 5,
       url: Bitforex,
       title: 'Bitforex',
       link: "https://www.bitforex.com/en/spot/u_usdt",
     },
     {
       id: 6,
       url: Digifinex,
       title: 'Digifinex',
       link: "https://www.digifinex.com/en-ww/trade/USDT/U",
     },
     {
       id: 7,
       url: PancakeSwap,
       title: 'PancakeSwap',
       link: "https://pancakeswap.finance/swap?outputCurrency=0x89dB9B433FeC1307d3dc8908f2813e9f7a1d38F0&chainId=56&inputCurrency=BNB"
     },
   ],
   footer_link_item: [
      {
         id:1,
         name:"ABOUT",
         url:"https://go.unidef.org/au"
      },
      {
         id:2,
         name:"TERMS",
         url:"https://go.unidef.org/tos"
      },
      {
         id:3,
         name:"PRIVACY",
         url:"https://go.unidef.org/ps"
      },
      {
         id:4,
         name:"DISCLAIMER",
         url:"https://go.unidef.org/fcd"
      },
   ]
}