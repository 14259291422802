import Loadable from 'react-loadable';
const Loading = ({ error }) => {
  if (error) {
    return 'Oh nooess!';
  } else {
    return '';
  }
}
const UnidefCoinScreen = Loadable({
  loader: () => import(/* webpackChunkName: "unidefcoinPage" */'../pages/UnidefCoinScreen'),
  loading: Loading
});
export const routePaths = {
  unidefcoinScreen: "/",
};
export const authRoutes = [
  {
    title: "UnidefCoinScreen",
    path: routePaths.unidefcoinScreen,
    url: routePaths.unidefcoinScreen,
    exact: true,
    element: <UnidefCoinScreen />
  },
];