import { createSlice } from '@reduxjs/toolkit'
export const languageReducer = createSlice({
  name: 'lang',
  initialState: {
    value: 'English',
  },
  reducers: {
    changeLang: (state, action) => {
      state.value = action.payload
    },
  },
})
export const { changeLang } = languageReducer.actions
export const selectedLang = (state) => state.language.value
export default languageReducer.reducer
