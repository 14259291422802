import React from "react";
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import {
  authRoutes,
} from "../routes";
const LoggedInRoutes = () =>
  authRoutes.map((r, i) => (
    <Route
      key={i}
      path={r.path}
      exact={r.exact}
      render={props => <r.main {...props} />}
      element={r.element}
    />
  ));
const Main = React.memo(props => {
  return (
    <Router>
      <Routes>
        {
          LoggedInRoutes()
        }
      </Routes>
    </Router>
  )
});
export default Main;